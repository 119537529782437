@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin custom-time-range-inputs-theme($theme, $typography) {
    $warn: map-get($theme, warn);

    .trds-custom-time-range-inputs {
        .time-input .invalid {
            border-color: mat.get-color-from-palette($warn, A200) !important;
        }
    }
}
