@use '@angular/material' as mat;
@use '../solidus-black/colors.scss' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin menu-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    // Define any styles affected by the theme.
    .mat-mdc-menu-panel,
    .mat-mdc-autocomplete-panel {
        background: colors.$bg-surface-3;

        .selected-item {
            color: mat.get-color-from-palette($accent, 500);
        }
    }

    div.mdc-menu-surface.mat-mdc-autocomplete-panel {
        padding: 0;
    }

    div.sort-menu {
        overflow: hidden;
        max-height: 35rem;
    }
    
    .cdk-overlay-pane:not(.mat-mdc-tooltip-panel):not(.trds-select-panel) {
        min-width: 10rem;
        width: fit-content;
    }  
    
    .cdk-overlay-pane.trds-select-panel {
        min-width: 9.1rem;
    }

    .cdk-overlay-pane.width-fit-content {
        width: fit-content!important;
    }

    @media (max-width: calc(#{$window-m - 1px})) {
        .cdk-overlay-pane.mat-datepicker-popup {
            bottom: unset !important;
            right: unset !important;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%) !important;
        }
    }

    @media (max-width: calc(#{$window-s - 1px})) {
        .cdk-overlay-pane:not(.mat-mdc-tooltip-panel):not(.mat-datepicker-popup) {
            position: fixed !important;
            width: 90vw !important;
            max-width: 90vw !important;
            max-height: 90vh !important;
            transform: none !important;
            min-width: unset !important;
            left: 5% !important;
            top: 5% !important;
            bottom: unset !important;
            box-shadow: 0 0 200px colors.$bg-surface-1;
            border-radius: $radius-sm;

            .mat-mdc-select-panel,
            .mat-mdc-menu-panel {
                width: 100% !important;
                min-width: 100% !important;
                max-width: 100% !important;
                max-height: 100% !important;
                transform: none !important;
            }

            .mat-mdc-select-panel-wrap,
            .mat-mdc-menu-panel {
                border-radius: $radius-sm;
                box-shadow: 0 0 0 1000px rgba(colors.$bg-surface-3, 0.75);
                max-width: 100%;
            }
        }
    }
}
