@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin events-description-theme($theme, $typography) {
    trds-events-description {

        .side-label,
        .event-type-title {
            @include mat.typography-level($typography, body-2);
            line-height: 1.4rem;
        }

        mat-icon.event-type-icon svg {
            width: 16px;
            height: 16px;
        }
    }
}
