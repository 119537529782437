@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin paginator-theme($theme) {
    $accent: map-get($theme, accent);
    
    $icon-size: 28px;

    mat-paginator.mat-mdc-paginator {
        background-color: transparent;
        white-space: nowrap;
        height: 3.3rem;
        display: flex;
        align-items: center;

        button:hover svg {
            fill: mat.get-color-from-palette($accent, 100);
        }

        &.size-sm {
            @include flex-layout(center);
            height: $icon-size;

            button[mat-icon-button] {
                width: $icon-size;
                height: $icon-size;
                line-height: $icon-size;
                padding: 0;
                @include flex-layout(center, center);
                margin: 0 2px;
            }
        }
    }

    @media (max-width: calc(#{$window-xxs - 1px})) {
        mat-paginator.mat-mdc-paginator {
            .mat-mdc-paginator-range-label {
                margin: 0 $half-gap 0 0;
            }

            button[mat-icon-button] {
                width: $icon-size;
                height: $icon-size;
                line-height: $icon-size;
                padding: 0;
                @include flex-layout(center, center);
            }

            .mat-mdc-icon-button .mat-mdc-button-touch-target {
                width: $icon-size;
                height: $icon-size;
                line-height: $icon-size;
            }
        }
    }
}
