@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin match-details-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-match-details {
        background-color: colors.$bg-surface-2;

        &:last-child {
            border-left: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .row {
            &:not(:last-child) {
                border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
            }

            .input-name {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
