@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-details-sidenav-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .trds-alert-details-sidenav {
        .actions-bar.sidebar-open .active {
            color: mat.get-color-from-palette($accent);
        }

        .main-content {
            background: colors.$app-background;
        }

        .dr-panel {
            background: colors.$app-background;
        }

        .resizer {
            background-color: colors.$bg-surface-3;
        }

        .alert-container {
            background: colors.$bg-surface-2;
        }

        .trds-grid {
            @include grid-rows(repeat(3, 2fr 3fr), $grid-row-height, $three-half-gaps);
            
            .trds-grid {
                margin-bottom: 0;
            }
        }

        .trds-ag-grid-transactions-alert-details-table {
            .table-active-filters {
                height: 0;

                &.has-filters {
                    height: auto;
                }
            }
        }

        .medium-size {
            .trds-grid {
                .row {
                    grid-template-columns: repeat(3, 1fr);
                    gap: $half-gap;
                }

                .key {
                    grid-row-start: 1;
                }

                &.four-columns .row {
                    grid-template-columns: repeat(2, 1fr);
                }


                .key:nth-child(7),
                .key:nth-child(9),
                .key:nth-child(11) {
                    grid-row-start: 3;
                }
            }

            .key-value-container {
                .key-value-cell {
                    flex-wrap: wrap;
                }

                .key,
                .value {
                    width: 100%;
                }
            }
        }

        .sm-size {
            .trds-grid{
                .row {
                    grid-template-columns: repeat(2, 1fr);

                    .key {
                        grid-column-start: 1;
                        grid-row-start: auto;
                    }
                }
            }

            .paginator-wrap {
                width: 100%;
            }

            .controls {
                margin: 0 calc(-1 * #{$inner-padding-sm});
                padding: $five-quarter-gaps $inner-padding-sm 0 $inner-padding-sm;

                .trds-form-field,
                .trds-column-selector {
                    margin: 0;
                }
            }

            .key-value-container {
                .key-value-cell {
                    width: 100%;
                }

                .key,
                .value {
                    width: 50%;

                    &:empty {
                        padding: 0;
                    }
                }
            }
        }

        .medium-size,
        .sm-size {
            .controls-container {
                flex-wrap: wrap;

                .selector-wrap,
                .trds-form-field {
                    width: 100%;
                }

                .trds-column-selector {
                    width: calc(100% - 4rem);
                }
            }

            .controls {
                flex-wrap: wrap;
            }
        }
    }
}
