@use 'sass:map';
@use '@angular/material' as mat;
@use './theme/solidus-black/colors' as colors;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// import specific app styling functions
@import './theme/functions.scss';
// Plus imports for other components in your app.
@import './theme/solidus-black/typography.scss';
@import './theme/components.theme.scss';
@import './theme/app-components.theme.scss';
@import './theme/mixins/mixins.scss';

@import 'ag-grid-enterprise/styles/ag-grid.css';
@import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
@import 'ag-grid-enterprise/styles/agGridMaterialFont.css';

@import 'tippy.js/dist/tippy.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($tardis-typography);`
@include mat.typography-hierarchy($tardis-typography);
@include mat.core();
// Must be called after "mat-core()" to override the foreground/background paletes

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tardis-primary: mat.define-palette(colors.$solidus-primary);
$tardis-accent: mat.define-palette(colors.$solidus-accent);

// The warn palette is optional (defaults to red).
$tardis-warn: mat.define-palette(colors.$solidus-warn, A200, A400, A700);

// Create the theme object (a Sass map containing all of the palettes).

$tardis-theme: mat.define-dark-theme((
  color: (
    primary: $tardis-primary,
    accent: $tardis-accent,
    warn: $tardis-warn,
  ),
  typography: $tardis-typography,
  density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($tardis-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($tardis-theme);

@include mat.all-component-themes($tardis-theme);
@include custom-component-theme($tardis-theme, $tardis-typography);
@include component-theme($tardis-theme, $tardis-typography);

.accent-default {
    $tardis-theme-accent: mat.define-dark-theme((
        color: (
          primary: $tardis-accent,
          accent: $tardis-accent,
          warn: $tardis-warn,
        ),
        typography: $tardis-typography,
        density: 0,
      ));

    @include mat.all-component-themes($tardis-theme-accent);
}

/* You can add global styles to this file, and also import other style files */
// $background: map-get($theme, background);
html,
body {
    height: 100%;
    margin: 0;
    font-size: 14px;
}

.mat-app-background {
    background-color: colors.$app-background;
}

.mat-typography h2 {
    color: mat.get-color-from-palette(map-get($tardis-theme, foreground), secondary-text);
    margin: $half-gap 0 $gap 0;
}


.mat-typography p {
    margin-bottom: $gap;
}

.secondary-color-icon {
    color: mat.get-color-from-palette(map-get($tardis-theme, foreground), secondary-text);
}

.error-text,
mat-error {
    color: mat.get-color-from-palette($tardis-warn, A400);
}

// changes the color of default inputs icons
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

a {
    color: mat.get-color-from-palette(map-get($tardis-theme, primary), 500);
}

.cdk-overlay-container{
    z-index: 999; //lower then fixed header z-index so it goes behind it
}