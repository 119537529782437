@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin key-value-grid-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .external-link:hover {
        color: mat.get-color-from-palette($accent, 100);
    }

    .key-value-cell {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .key {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }

    @media (max-width: calc(#{$window-m - 1px})) {
        .content-wrapper .key-value-container {
            .key-value-cell {
                flex-wrap: wrap;
            }
            
            .key,
            .value {
                width: 100%;
            }
        }
    }

    @media (max-width: calc(#{$window-xxs - 1px})) {
        .content-wrapper .key-value-container {
            .key-value-cell {
                width: 100%;
            }
            
            .key,
            .value {
                width: 50%;

                &:empty {
                    padding: 0;
                }
            }
        }
    } 

}
