@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin email-configuration-theme($theme, $typography) {
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);

    $radio-button-size: 15px;

    .email-configuration {
        .mat-radio-container {
            height: $radio-button-size;
            width: $radio-button-size;
            font-size: $radio-button-size;
        }
        .mat-radio-outer-circle {
            height: $radio-button-size;
            width: $radio-button-size;
            font-size: $radio-button-size;
        }
        .mat-radio-inner-circle {
            height: $radio-button-size;
            width: $radio-button-size;
            font-size: $radio-button-size;
        }
        .mat-radio-button .mat-radio-ripple {
            height: 26px;
            width: 26px;
            left: calc(50% - 13px);
            top: calc(50% - 13px);
            font-size: $radio-button-size;
        }

        .case-assigned-days {
            background-color: transparent;
            border-color: rgba(mat.get-color-from-palette($primary, 100), 0.3) !important;
            color: mat.get-color-from-palette($primary, 100);

            &.invalid {
                border-color: mat.get-color-from-palette($warn, A200) !important;
            }
        }

        .border-item .list-item {
            border-bottom: 1px solid colors.$bg-surface-3;
        }

        .header .radio-button-column,
        .header .description-column {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .description-column-note {
            font-size: mat.font-size($typography, body-2);
        }
    }
}
