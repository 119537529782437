@use '@angular/material' as mat;
@import 'src/theme/mixins/mixins';

$window-medium: 1100px;

@mixin complex-details-component-theme($theme) {
    // Extract the palettes you need from the theme definition.

    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-complex-details {
        h2 {
            color: mat.get-color-from-palette($foreground, secondary-text);
            margin: $half-gap 0 $three-half-gaps 0;
        }

        // Vertical tabs
        // Temporary styles. Should be removed when we replace Mat tabs with our own component
        @media (min-width: $window-medium) {
            mat-tab-group.vertical-tabs {
                flex-direction: row;
                padding-top: $gap;

                mat-tab-header {
                    border-bottom: none;
                    padding-right: $gap;
                    width: 30%;
                    max-width: 30%;

                    .mat-mdc-tab-labels {
                        flex-direction: column;
                        border-radius: 0;

                        .mdc-tab {
                            background: inherit;
                            display: inline-block;
                            text-transform: capitalize;
                            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

                            .mdc-tab__content,
                            .mdc-tab__text-label {
                                height: 100%;
                                width: 100%;
                                
                                justify-content: space-between;
                                text-align: left;
                            }
                        }
                    }
                }
                
                .mat-mdc-tab-body-wrapper {
                    width: 70%;
                }
            }
        }
    }
}
