@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin market-inspection-tb-select-header-theme($theme, $typography) {
    $accent: map-get($theme, accent);
    .market-inspection-tb-select-header {
        width: 100%;

        .custom-header .selector {
            border: 1px solid colors.$bg-surface-4;
            border-radius: $radius-sm;
            background-color: colors.$bg-surface-3;

            &.default .mat-mdc-select-value-text {
                color: colors.$disabled-color;
            }
        }
    }

    .custom-header-mat-option {
        &:hover {
            background-color: mat.get-color-from-palette($accent, 500) !important;
            color: colors.$bg-surface-1;
        }

        &.mdc-list-item--selected:hover {
            color: mat.get-contrast-color-from-palette($accent, 700) !important;
            background-color: colors.$bg-surface-4 !important;
        }

        &.default {
            color: colors.$disabled-color;

            &:hover {
                color: colors.$bg-surface-1;
            }

            &.mat-active:hover {
                color: colors.$disabled-color !important;
            }
        }

        .required::after {
            content: '*';
        }
    }
}
