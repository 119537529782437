@use '@angular/material' as mat;
@import 'src/theme/components/scrollbar.theme';

@mixin plaid-account-identity-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-account-identity-dialog {
        .header {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .account {
            &:after {
                border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
