@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin market-inspection-filters-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);

    .trds-market-inspection-filters {
        .filter-line {
            border-radius: $radius-sm;

            &.main-line {
                border: 1px solid colors.$bg-surface-3;
            }

            &.tools-line {
                background-color: colors.$bg-surface-2;

                button .mdc-button__label {
                    padding-left: 0;
                }
            }

            &.alert-panel {
                border: 1px solid rgb(colors.$chart-blue, 0.35);
            }
        }

        .open-in-market-inspection {
            background-color: rgb(colors.$chart-blue, 0.35);
        }

        .reset-alert-view-btn-background-color{
            background-color: rgb(colors.$chart-blue, 0.6);
        }

        .reset-btn-disabled-btn-background-color{
            background-color: colors.$bg-surface-2;
        }

        .delimiter {
            background: colors.$bg-surface-3;
        }

        .header {
            @include mat.typography-level($typography, body-2);
            color: mat.get-color-from-palette($foreground, secondary-text);

            .selected-candle-size {
                color: mat.get-color-from-palette($accent, 700);
            }
        }

        .xl-size.has-uploader {
            trds-symbol-exchange-filter form,
            .main-line .second-part,
            .main-line-gap {
                column-gap: $gap;
            }

            .filter-input {
                width: 14.5rem;
            }
        }

        .extras-size {
            .filter-line:not(.alert-panel) {
                &.main-line-gap,
                &.second-line {
                    border: none;
                    box-shadow: none;

                    .first-part,
                    .toggle-group-wrap,
                    trds-file-uploader,
                    .time-range-segment {
                        border: 1px solid colors.$bg-surface-3;
                        border-radius: $radius-sm;
                        width: 100%;
                    }

                    form {
                        margin-bottom: 0;
                        flex: 1;
                    }
                }
            }

            trds-symbol-exchange-filter form {
                row-gap: $gap;
            }

            .with-dropdown-button {
                padding: 0 $half-gap;
            }
        }
    }
}
