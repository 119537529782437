@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-audit-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-case-details-audit {
        .event:not(:last-child) {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }
    }
}
