@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin side-bar-buttons-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);

    .trds-side-bar-buttons {
        .active {
            color: mat.get-color-from-palette($accent);
        }
    }
}
