@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-page-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-case-details {
        .page-header {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .attention {
            background-color: rgba(colors.$bg-surface-2, 0.85);
            color: mat.get-color-from-palette($accent, 100);
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 2px 5px;
            mat-icon {
                margin-right: 5px;
            }
        }

        .container {
            background: colors.$bg-surface-1;
            @include mat.elevation(2);
            border-radius: 4px;
        }
    }

    .has-opened-panel .trds-column-selector{
        @media (max-width: calc(#{$window-s} - 1px + #{$sidebar-panel-width})) {
            trds-form-field {
                width: 100%;
            }
        }
    }
}
