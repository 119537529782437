@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin trds-form-field-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);

    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    $outline-color: mat.get-color-from-palette($foreground, divider, 0.3);
    $outline-color-hover: mat.get-color-from-palette($foreground, divider, 1);
    $label-color: mat.get-color-from-palette($foreground, secondary-text);

    .trds-form-field {
        fieldset {
            box-sizing: border-box;
            color: $outline-color;
            position: relative;

            legend {
                @include mat.typography-level($typography, body-2);
                color: $label-color;
            }

            [matsuffix] {
                position: absolute;
                right: $half-gap;
            }

            &.outline {
                border: 1px solid currentColor;
                border-radius: $radius-sm;

                input,
                mat-select,
                textarea {
                    padding: 0 $half-gap;
                    display: block;
                }
            }

            &.fill {
                border: 1px solid transparent;
                background-color: mat.get-color-from-palette($background, background);
                border-radius: $radius-sm $radius-sm 0 0;
                border-bottom-color: currentColor;

                input,
                mat-select,
                textarea {
                    padding: 0 $half-gap;
                }

                input:not(:only-child) {
                    padding-right: calc($small-icon-size + $half-gap);
                }
            }

            &.standard {
                border: 1px solid transparent;
                border-radius: 0;
                border-bottom-color: currentColor;
            }

            &:hover {
                color: $outline-color-hover;
            }

            &:focus-within {
                color: mat.get-color-from-palette($accent, 500);

                legend {
                    color: mat.get-color-from-palette($accent, 500);
                }
            }
        }

        &.trds-field-invalid {
            fieldset,
            fieldset:hover {
                color: mat.get-color-from-palette($warn, A200);

                legend {
                    color: mat.get-color-from-palette($warn, A200);
                }
            }
        }

        &.trds-field-disabled {
            fieldset,
            fieldset:hover {
                color: $outline-color;

                // If the styles for filled and disabled field is required
                // we should add the styles in the same way as in material Form fields
                // It is required the deep changes
                legend,
                .field-content {
                    color: mat.get-color-from-palette($foreground, disabled);
                }
            }
        }

        .field-content {
            color: mat.get-color-from-palette($foreground, text);

            input,
            mat-select {
                line-height: $trds-field-height;
                height: $trds-field-height;
            }
        }

        &.size-sm .field-content {
            input,
            mat-select {
                line-height: $trds-field-height-sm;
                height: $trds-field-height-sm;
            }
        }

        fieldset:focus-within,
        &.trds-field-invalid fieldset {
            .field-content {

                input,
                textarea,
                mat-select {
                    margin: -1px;
                    width: calc(100% + 2px);
                }

                
                [matsuffix] {
                    right: calc($half-gap - 1px);
                }
            }
        }

        input {
            border: none;
            background: transparent;
            width: 100%;
            flex: 1;
            color: inherit;
            margin-top: 0;
            padding: 0;
            height: $trds-field-height;
            line-height: $trds-field-height; // fixes line-height inside the input in different browsers
            @include mat.typography-level($typography, body-1);

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }

        }
        
        button.trds-suffix-button {
            @include small-btn-styles();
        }

        &.button-select {
            fieldset {
                border-color: mat.get-color-from-palette($foreground, text);

                .mat-mdc-select-placeholder {
                    color: mat.get-color-from-palette($primary, 500);
                    transition-delay: 0s;
                }

                &:hover {
                    background: rgba(mat.get-color-from-palette($primary, 500), 0.08);

                    .mat-mdc-select-placeholder {
                        color: mat.get-color-from-palette($accent, 100);
                    }
                }
            }
        }
    }

    .trds-select-panel .mat-mdc-select-panel.outline-select-panel {
        margin-left: (-$half-gap);
    }
}
