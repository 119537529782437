@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin system-configuration-theme($theme) {
    $warn: map-get($theme, warn);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .trds-system-configuration {
        .config-name {
            background-color: colors.$bg-surface-3;
        }

        .top-divider {
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .error-icon {
            color: mat.get-color-from-palette($warn, A400);
        }

        .success-icon {
            color: colors.$score-low;
        }

        .config-name-selected {
            border-bottom: 1px solid mat.get-color-from-palette($accent);
        }

        mat-panel-description.api-key-description {
            :hover{
                color: mat.get-color-from-palette($accent, 100);
            }
          }
    }
}
