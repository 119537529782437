@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin datepicker-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .mat-calendar button:disabled {
        color: mat.get-color-from-palette($foreground, disabled-button);
    }
    // Define any styles affected by the theme.
    mat-datepicker-content.mat-datepicker-content {
        background-color: colors.$bg-surface-3;
    }

    .table-with-filters-date-picker {
        margin-left: $datepicker-presets-width;

        &.no-template-header {
            .chips-container {
                margin-top: -$half-gap;
            }
        }

        &.with-time-range-inputs {
            margin-bottom: $triple-gap;
        }
    }

    .trds-date-range-picker-presets {
        background: colors.$bg-surface-2;

        .link.accent {
            color: mat.get-color-from-palette(map-get($theme, accent), 500);
        }
    }

    .trds-time-range-inputs,
    .trds-custom-time-range-inputs {
        background: transparent;

        span {
            @include mat.typography-level($typography, body-1);
            color: mat.get-color-from-palette(map-get($tardis-theme, primary), 500);
        }

        input {
            @include mat.typography-level($typography, body-1);
            background: transparent;
            color: mat.get-color-from-palette(map-get($tardis-theme, primary), 500);
            border-bottom: 1px mat.get-color-from-palette(map-get($tardis-theme, primary), 500) solid;

            &:hover,
            &:focus {
                color: mat.get-color-from-palette(map-get($tardis-theme, primary), 100);
                border-color: mat.get-color-from-palette(map-get($tardis-theme, primary), 100);
            }
        }
    }

    .trds-date-range-picker-header {
        .calendar-header-label {
            font-weight: 500;
        }

        .today-icon {
            font-size: 1.3rem;

            &:hover {
                color: mat.get-color-from-palette(map-get($theme, accent), 500);
            }
        }
    }

    @media (max-width: calc(#{$window-s} - 1px)) {
        .datepicker-toggle-btn {
            display: none;
        }
    }

    @media (max-width: calc(#{$window-xs - 1px})) {
        .table-with-filters-date-picker {
            margin-left: 0;

            .mat-calendar-content {
                display: none;
            }
        }
    }
}
