@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin cases-bulk-update-error-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-cases-bulk-update-error {
        .error-message {
            background: mat.get-color-from-palette($accent, 500);
            color: colors.$bg-surface-2;
        }

        table {
            background: colors.$bg-surface-4;
            border-radius: $radius-sm;

            thead {
                th {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }

            tr {
                @include mat.typography-level($typography, body-2);
                border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
