@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin advanced-search-tables-theme($theme) {
    $accent: map-get($theme, accent);

    .trds-cases-table,
    .trds-alerts-table,
    .trds-applications-table,
    .trds-tm-table,
    .trds-ms-table,
    .trds-algo-segments-table,
    .trds-users-list,
    .trds-clients-table,
    .trds-activities-table,
    .trds-file-upload-table,
    .trds-rest-api-table {
        mat-header-cell[aria-expanded='true'],
        .mat-mdc-header-cell[aria-expanded='true'] {
            color: mat.get-color-from-palette($accent);
        }

        .active-column mat-icon {
            color: mat.get-color-from-palette($accent);
        }
    }
}
