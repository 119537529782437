@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin sidenav-component-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    // Define any styles affected by the theme.

    .trds-sidenav .mat-toolbar {
        background-color: colors.$bg-surface-3;

        .app-logo {
            color: mat.get-color-from-palette($foreground, text);

            &:hover {
                color: mat.get-color-from-palette($accent);
            }
        }
        
        .link.active {
            color: mat.get-color-from-palette($accent);
        }
    }
}
