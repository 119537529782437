@use '@angular/material' as mat;

@mixin score-color($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    $foreground: map-get($theme, foreground);

    &.score {
        @include mat.typography-level($typography, headline-6);

        &.low {
            color: mat.get-color-from-palette($foreground, low-risk);
        }
        &.medium {
            color: mat.get-color-from-palette($foreground, medium-risk);
        }
        &.high {
            color: mat.get-color-from-palette($foreground, high-risk);
        }
    }
}
