@mixin checkbox-theme($theme, $typography) {
    mat-checkbox,
    mat-radio-button {
        display: block;

        label {
            white-space: normal;
            align-items: flex-start;
            cursor: pointer;
            @include mat.typography-level($typography, body-1);
        }

        span {
            margin-top: 0;
        }
        
        &.mdc-checkbox--disabled {
            label {
                cursor: default;
            }

            svg {
                color: colors.$bg-surface-3!important;
            }
        }
    }
}
