@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-details-trmt-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    .trds-alert-details-trmt {
        .external-link:hover {
            color: mat.get-color-from-palette($accent, 100);
        }
    }
}
