@use '@angular/material' as mat;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin tab-component-theme($theme) {
    // Define any styles affected by the theme.
    $foreground: map-get($theme, foreground);

    $tab-label-height: 3.5rem;

    mat-tab-group {
        border-radius: $radius-sm;
        overflow: hidden;

        mat-tab-header {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        &.hide-mat-tab-header mat-tab-header {
             display: none;
        }
    }

    .fullpage-tab-body {
        height: calc(100vh - #{$tab-label-height} - #{$topnav} - #{$gap});
    }
}
