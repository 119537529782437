@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin priority-select-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .trds-priority-select {
        .menu-btn {
            color: mat.get-color-from-palette($foreground, secondary-text);

            &:hover {
                mat-icon.edit-icon {
                    color: mat.get-color-from-palette($accent, 100);
                }
            }

            &[aria-expanded] {

            }
        }
    }

    .menu-panel {
        width: 11rem; // It should be the same value as the max-width as button from priority-select.component.scss file
    }

    .menu-panel-large {
        width: 18rem;
    }
}
