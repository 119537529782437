@use '@angular/material' as mat;
@use '../solidus-black/colors.scss' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin select-menu-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $accent: map-get($theme, accent);

    // Define any styles affected by the theme.
    div.mdc-menu-surface.mat-mdc-select-panel {
        background: colors.$bg-surface-3;
        padding: 0;
        border-radius: $radius-sm;
    }

    

    .mat-option-text .selected {
        color: mat.get-color-from-palette($accent);
    }

    mat-option.mat-mdc-option {
        font-size: mat.font-size($typography, body-1);
        min-height: 3.2rem;
        line-height: 1.5;
    }

    .mat-accent mat-option.multi-select-option:not(.mat-option-disabled) {
        color: mat.get-color-from-palette($foreground, base);
    }

    .mdc-list-item__primary-text {
        width: 100%;

        mat-icon {
            color: mat.get-color-from-palette($accent);
        }
    }

    mat-option.mat-selected,
    .mat-mdc-option.mdc-list-item--selected {
        color: mat.get-color-from-palette($accent);
    }

    .select-overlay-pane {
        min-width: fit-content;
        margin-top: $double-gap;
    }

    .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        color: mat.get-color-from-palette($foreground, text);
    }

    mat-selection-list .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
        margin-right: $half-gap;
    }
}
