@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables';

@mixin policy-form-theme($theme) {
    $foreground: map-get($theme, foreground);

    trds-policy-form {
        hr {
            height: 0;
            border: none;
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .policy-form-content {
            background: colors.$bg-surface-2;
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .mat-error.nextRunError {
            font-size: 75%;
            margin-top: -20px;
        }
    }
}
