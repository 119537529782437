@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/solidus-black/typography';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin tooltip-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .mdc-tooltip[class*='tooltip'],
    .mdc-tooltip.trds-tooltip,
    .trds-tooltip,
    trds-cell-tooltip,
    trds-date-cell-tooltip {
        .mdc-tooltip__surface,
        .cell-tooltip-value {
            padding: $half-gap;
            border-radius: $radius-sm;
            max-width: 18.5rem;
            max-height: 50vh;
            margin: $gap $half-gap;
            @include trds-tooltip();
            @include mat.elevation(4);
            border: 1px solid colors.$tooltip-bd-color;
            background-color: colors.$bg-surface-2;
            color:  mat.get-color-from-palette($foreground, text);
        }

        &.multiline-tooltip {
            white-space: pre-line;

            &.center {
                text-align: center;
            }

            .mdc-tooltip__surface {
                overflow: auto;
                max-width: 300px;
            }
        }

        &.tooltip-lg .mdc-tooltip__surface {
            max-width: 38.5rem;
        }

        &.left {
            white-space: pre-line;

            & > * {
                text-align: left;
                overflow: auto;
                max-width: 400px;
                max-height: unset;
            }
        }

        &.multiline-bullets-tooltip {
            white-space: pre-wrap;
            max-width: 50vw;
            max-height: 60vh;
            overflow-y: auto;
        }

        &.fullwidth-tooltip {
            max-width: max-content;
        }

        &.width-md {
            max-width: 290px;
        }

        &.text-capitalize {
            text-transform: capitalize;
        }

        &.file-tooltip {
            max-width: 60vw;
            word-break: break-all;
        }
    }

    .mat-mdc-tooltip-panel-above .mdc-tooltip .mdc-tooltip__surface {
        overflow: visible;
        
        &:after {
            content: '';
            width: 9px;
            height: 9px;
            background: colors.$bg-surface-2;
            border-right: 1px solid colors.$tooltip-bd-color;
            border-bottom: 1px solid colors.$tooltip-bd-color;
            transform: rotate(45deg);
            display: block;
            left: 50%;
            margin-left: -5px;
            bottom: -6px;
            position: absolute;
        }
    }
}
