@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables';

@mixin policy-details-theme($theme) {
    $foreground: map-get($theme, foreground);

    trds-policy-details .dialog-icon-header {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }
}
