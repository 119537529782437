@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin market-inspection-page-theme($theme, $typography) {
    .trds-market-inspection-new {
        .mi-page-content .empty-chart-overload .no-chart-data-message {
            color: colors.$low-priority;
        }

        .smart-zoom-loading-overload {
            background-color: rgb(colors.$bg-surface-2, 0.5);
        }

        trds-layout .inner-padding {
            padding-left: $gap;
        }
    }
}
