@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin market-inspection-overlay-theme($theme, $typography) {
    .overlay-content-wrap {
        background-color: colors.$bg-surface-3;
        border-radius: $radius-sm;
    }

    trds-mi-overlay button[mat-button].mat-icon-button mat-icon.mat-icon,
    trds-mi-overlay button[mat-button] mat-icon.mat-icon.icon-button,
    trds-floating-autocomplete-input button[mat-button].icon-button mat-icon.mat-icon,
    trds-mi-overlay button[mat-button] .dropdown-button-wrap mat-icon.mat-icon{
        margin: 0;
    }
}
