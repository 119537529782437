@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin kyc-checks-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .trds-kyc-checks {
        .link {
            color: mat.get-color-from-palette($foreground, base);

            &:hover {
                color: mat.get-color-from-palette($accent, 100);
            }
        }
    }
}
