@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin reset-password-notification-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-reset-password-notification .description {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }
}
