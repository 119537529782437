@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin risk-model-input-theme($theme, $typography) {

    .trds-risk-model-input {
        .item {
            background-color: colors.$bg-surface-3;

            &.changed {
                background-color: rgba(colors.$modified-param, 0.3);
            }

            &.invalid {
                background-color: rgba(colors.$invalid-param, 0.3);
            }
        }

        .original {
            @include mat.typography-level($typography, caption);
        }

        &.simple-view {
            .item {
                background: none;
            }

            .error-text{
                @include mat.typography-level($typography, body-2);
                margin-top: -(mat.line-height($typography, body-2));
            }
        }
    }
}
