@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin market-inspection-chart-theme($theme, $typography) {
    @include score-color-theme();

    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    .trds-market-inspection-chart {
        .marker {
            &.hover-marker:hover::before,
            &.selected::before {
                border-color: mat.get-color-from-palette($accent, 100);
            }

            &.statistic-marker,
            &.empty-marker {
                border: 1px solid colors.$light-highlight-text;

                &.selected {
                    outline: 3px solid mat.get-color-from-palette($accent, 500);
                }
            }

            &.news-marker,
            &.alert-marker {
                color: mat.get-color-from-palette($accent, 100);

                .tooltip {
                    border: 1px solid rgba(mat.get-color-from-palette($accent, 300), 0.35);
                }
            }

            &.alert-marker,
            &.entry-marker,
            &.execution-marker {
                &.buy-marker {
                    color: colors.$side-buy;
                }

                &.sell-marker {
                    color: colors.$side-sell;
                }
            }
        }

        .mi-v2-chart-container {
            .mi-v2-chart {
                border: 1px solid mat.get-color-from-palette($foreground, divider);

                .chart-tools-panel {
                    background-color: rgba(colors.$bg-surface-1, 0.7);

                    .events-toggle-button.single-events-toggle {
                        border: 1px solid rgba(mat.get-color-from-palette($primary, 100), 0.5);

                        &:hover {
                            border-color: mat.get-color-from-palette($accent, 100);
                        }

                        &:disabled {
                            border-color: mat.get-color-from-palette($primary, 700);
                        }
                    }
                }
            }

            .stx_candle_down,
            .stx_candle_shadow_down {
                color: colors.$side-sell;
            }

            .stx_candle_up,
            .stx_candle_shadow_up {
                color: colors.$side-buy;
            }

            .stx_grid,
            .stx_grid_dark,
            .stx_grid_border {
                /* Default Grid */
                color: mat.get-color-from-palette($foreground, divider);
                border-style: solid;
            }

            .stx_xaxis,
            .stx_xaxis_dark,
            .stx_yaxis {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }

            // panel control
            .stx-panel-control {
                overflow: unset;

                // hide panel control buttons
                .stx-btn-panel {
                    display: none;
                }

                // panel title
                .stx-panel-title {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    font-family: mat.font-family($typography, body-2);
                    font-size: mat.font-size($typography, body-2);
                    padding-left: 0;
                    margin-left: $one-third-gap;
                    text-transform: none;

                    &.client-volumes-bar {
                        &::after {
                            content: ' ';
                            -webkit-mask-image: url(/assets/images/icons/info.svg);
                            mask-image: url(/assets/images/icons/info.svg);
                            background-color: mat.get-color-from-palette($foreground, secondary-text);

                            position: absolute;
                            top: 3px;
                            right: -3px;
                            width: 18px;
                            height: 18px;
                            mask-size: contain;
                        }

                        &:hover {
                            cursor: pointer;

                            &::before {
                                content: 'When client Ids are selected, the volume bars display the volumes associated with those clients.';
                                position: absolute;
                                top: 25px;
                                left: 20px;
                                width: max-content;
                                max-width: 300px;
                                padding: 5px 10px;
                                border-radius: $radius-sm;

                                @include trds-tooltip();
                                border: 1px solid colors.$tooltip-bd-color;
                                background-color: colors.$bg-surface-2;
                                color: mat.get-color-from-palette($foreground, text);
                            }
                        }
                    }
                }
            }

            // canvas watermarks text (f.e. 'Volume Not Available' text style)
            .stx_watermark {
                font-family: mat.font-family($typography, body-2);
                font-size: mat.font-size($typography, body-2);
            }

            div[class*='mi-agg-marker'],
            div[class*='mi-client-event-marker'] {
                &[class*='group-1'] {
                    border-color: var(--mi-group-marker-color-1);
                }

                &[class*='group-2'] {
                    border-color: var(--mi-group-marker-color-2);
                }

                &[class*='group-3'] {
                    border-color: var(--mi-group-marker-color-3);
                }

                &[class*='group-4'] {
                    border-color: var(--mi-group-marker-color-4);
                }

                &[class*='group-5'] {
                    border-color: var(--mi-group-marker-color-5);
                }

                &[class*='group-6'] {
                    border-color: var(--mi-group-marker-color-6);
                }

            }

            div[class*='group-trade'] {
                border-color: var(--mi-group-marker-color-trade);
            }
        }

        .button-filled.mat-accent {
            background: mat.get-color-from-palette($accent, 500);
            color: colors.$bg-surface-2;

            &:disabled {
                background-color: mat.get-color-from-palette($background, disabled-button);
                color: mat.get-color-from-palette($foreground, disabled-button);
            }
        }

        .alert-view-height {
            .ciq-lce-descripton,
            .stx-performance-marker.stx-marker-expand,
            .tooltip {
                max-height: 280px;
            }
        }

        .ciq-lce-descripton,
        .stx-performance-marker.stx-marker-expand,
        .tooltip {
            text-align: left;
            max-height: 300px;
            border-radius: $radius-sm;
            padding: $three-quarter-gaps;
            max-width: 18rem;
            @include trds-tooltip();
            @include mat.elevation(4);
            border: 1px solid colors.$tooltip-bd-color;
            background-color: colors.$bg-surface-2;
            color: mat.get-color-from-palette($foreground, text);

            p,
            h4 {
                color: mat.get-color-from-palette($primary, 100);
            }

            h4 {
                margin-bottom: $half-gap;
                letter-spacing: 0.14px;
            }

            a {
                color: inherit;
                text-decoration: none;

                .highlight {
                    font-weight: 600;
                    color: mat.get-color-from-palette($primary, 500);

                    &:hover {
                        color: mat.get-color-from-palette($accent, 100);
                    }
                }
            }

            p {
                border-top: none;
                padding-top: 0;
                margin: 0;
                @include chart-tooltip();
            }


            // delimiter between descriptions
            .marker_content h4:not(:first-child) {
                border-top: solid 1px colors.$bg-surface-5;
                padding-top: 1rem;
                margin-top: 1rem;
            }

            .marker_content {

                h4 {
                    height: 0;
                }

                br {
                    display: none;
                }

                .headline {

                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                        border-radius: 50%;
                        border: solid 2px;
                    }

                    &.group-1 {
                        img {
                            border-color: var(--mi-group-marker-color-1);
                        }
                    }

                    &.group-2 {
                        img {
                            border-color: var(--mi-group-marker-color-2);
                        }
                    }

                    &.group-3 {
                        img {
                            border-color: var(--mi-group-marker-color-3);
                        }
                    }

                    &.group-4 {
                        img {
                            border-color: var(--mi-group-marker-color-4);
                        }
                    }

                    &.group-5 {
                        img {
                            border-color: var(--mi-group-marker-color-5);
                        }
                    }

                    &.group-6 {
                        img {
                            border-color: var(--mi-group-marker-color-6);
                        }
                    }

                    .trade-marker {
                        width: 6px;
                        height: 6px;
                        background-color: var(--mi-group-marker-color-trade);
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }


                .description-line {
                    display: block;
                }

                .delimiter {
                    display: block;
                    height: 1rem;
                }

                .sell-side {
                    color: colors.$side-sell
                }

                .buy-side {
                    color: colors.$side-buy
                }
            }

            .secondary {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }

            &::-webkit-scrollbar-thumb {
                background-color: colors.$bg-surface-4;
            }

            .description-link-with-copy-button {
                display: inline-flex;
                padding-right: 1.42rem;
                align-items: center;

                .copy-button {
                    display: none;
                }

                &:hover {
                    padding-right: 0;
                }

                &:hover .copy-button,
                .copy-button.clicked {
                    display: inline-flex;
                }
            }

            .copy-button {
                display: inline-flex;
                position: relative;
                cursor: pointer;
                font-weight: bold;
                margin-left: $one-third-gap;

                .description-copy-button {
                    background-color: mat.get-color-from-palette($primary, 100);
                    width: 14px;
                    height: 14px;
                    margin-left: 2px;
                    display: block;
                    -webkit-mask: url(/assets/images/icons/copy.svg) no-repeat center;
                    mask: url(/assets/images/icons/copy.svg) no-repeat center;
                }

                &.clicked,
                &:active:hover,
                &.clicked:hover {
                    .description-copy-button {
                        //background-color: mat.get-color-from-palette($accent, 300);
                        background-color: colors.$chart-green;

                        -webkit-mask: url(/assets/images/icons/check_circle.svg) no-repeat center;
                        mask: url(/assets/images/icons/check_circle.svg) no-repeat center;
                    }

                    &:hover::after {
                        display: none;
                    }
                }

                &:hover {
                    .description-copy-button {
                        background-color: mat.get-color-from-palette($accent, 100);
                    }

                    // todo: discuss with Rinat
                    // &::after {
                    //    @include chart-description-tooltip-element($primary, colors.$light-highlight-text);
                    //    content: 'Copy To Clipboard';
                    //}
                }
            }

            .hover-data {
                position: relative;

                &:hover::after {
                    @include chart-description-tooltip-element($primary, colors.$light-highlight-text);
                    content: attr(content);
                }
            }
        }
    }

    .stx-stem {
        border-left: 1px dotted transparent;
        height: 30px;
    }

    .stx-marker[class*='mi-agg-marker'] .stx-visual {
        width: 15px;
        height: 15px;
    }

    .stx-marker[class*='mi-client-event-marker-s_17'] .stx-visual {
        width: 17px;
        height: 17px;
    }
    .stx-marker[class*='mi-client-event-marker-s_20'] .stx-visual {
        width: 20px;
        height: 20px;
    }
    .stx-marker[class*='mi-client-event-marker-s_23'] .stx-visual {
        width: 23px;
        height: 23px;
    }
    .stx-marker[class*='mi-client-event-marker-s_26'] .stx-visual {
        width: 26px;
        height: 26px;
    }
    .stx-marker[class*='mi-client-event-marker-s_29'] .stx-visual {
        width: 29px;
        height: 29px;
    }
    .stx-marker[class*='mi-client-event-marker-s_32'] .stx-visual {
        width: 32px;
        height: 32px;
    }
    .stx-marker[class*='mi-client-event-marker-s_35'] .stx-visual {
        width: 35px;
        height: 35px;
    }
    .stx-marker[class*='mi-client-event-marker-s_38'] .stx-visual {
        width: 38px;
        height: 38px;
    }

    .stx-marker[class*='mi-trade-marker'] .stx-visual {
        width: 5px;
        height: 5px;
    }

    .stx_volume_up {
        border: #648a54;
    }

    .stx_volume_down {
        border: #9c250a;
    }

    .border-color {
        border: dashed 10px #000;
    }

    .marker-shadow {
        -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
    }

    .stx-crosshair-cursor-on {
        cursor: crosshair;
    }

    // from ChartIQ v.9.6 we started to get blue frame on chart elements click. This style helps to avoid this frame:
    .mi-v2-chart-container > * :focus-visible,
    .mi-v2-chart-container > * :focus
    {
        outline: none;
    }
}
