@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin algos-info-dialog($theme) {
    $warn: map-get($theme, warn);
    $accent: map-get($theme, accent);

    .trds-algos-info-dialog {
        .error-icon {
            color: mat.get-color-from-palette($warn, A400);
        }

        .success-icon {
            color: colors.$score-low;
        }
    }
}
