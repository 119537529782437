@use '@angular/material' as mat;

@mixin trds-attachment-theme($theme, $typography) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

        .trds-attachment.bordered {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

            &:focus-within:not(.header),
            &:hover:not(.header) {
                background: mat.get-color-from-palette($background, hover);
            }
        }
    
}
