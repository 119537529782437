$gap: 0.715rem; // ~ 10px 
$half-gap: #{$gap * 0.5};
$quarter-gap: #{$gap * 0.25};
$one-third-gap: #{$gap * 0.33};
$two-third-gap: #{$gap * 0.66};
$double-gap: #{$gap * 2};
$triple-gap: #{$gap * 3};
$four-gap: #{$gap * 4};
$five-gap: #{$gap * 5};
$six-gap: #{$gap * 6};

$tab-gap: 1.14rem;

$title-height: 1.71rem;
$linking-input-size: 20.75rem;

$logo-size: 2rem;

$three-quarter-gaps: calc(#{$quarter-gap} * 3); // 0.75rem
$five-quarter-gaps: calc(#{$quarter-gap} * 5); // 1.25rem
$seven-quarter-gaps: calc(#{$quarter-gap} * 7); // 1.75rem
$nine-quarter-gaps: calc(#{$quarter-gap} * 9); // 2.25rem

$three-half-gaps: calc(#{$half-gap} * 3); // 1.5rem
$five-half-gaps: calc(#{$half-gap} * 5); // 2.5rem
$seven-half-gaps: calc(#{$half-gap} * 7); // 3.5rem
$nine-half-gaps: calc(#{$half-gap} * 9); 

$font-size: 1rem;
$error-font-size: 0.75rem;
$main-title: 1.43rem; // ~20px
 
$btn-min-size: 2rem;
$btn-md-size: 2.14rem;

$lg-btn-height: 2.75rem;

$inner-padding: $double-gap;
$inner-padding-sm: 1.25rem;
$topgap: 42px;
$topnav: 42px;
$sidenav: 30px;
$sidebar-width: 400px;

$topgapcharts: 27.2rem;
$topgapSingleChart: 4.3rem;

$small-icon-size: 1.43rem; // ~20px. if 'small-icon-size' is changed, 'trds-field-height' should be changed too.
$small-icon-font: 1.29rem; // ~18px

$sm-icon-size: 1.14rem;
$xs-icon-size: 0.86rem;

$topGapTable: 9.5rem;

$app-header: 3rem;

$attachment-height: 4rem;

$editor-fields-default-width: 25rem;

$datepicker-presets-width: 10rem;

// 'trds-field-height' based on $small-icon-size variable. Height is to prevent inputs from resizing with and without icons.
// Should be in 'px' because if we use 'rem' mat select rounds line-height downwards in Chrome,
// so we have a difference in 1px between height in Chrome and Firefox
$trds-field-height: 36px;
$trds-field-height-sm: 26px;

$radius-sm: 4px;
$search-line-select-width: 14.28rem;

$mat-icon-button-length: 32px;

$tabs-dialog-height: 82vh;
$dialog-history-height: 35vh;

$row-height: 3.4rem;
$grid-row-height: 3.2rem;

$cell-gap: 6px;

$small-dialog-side-padding: 24px;

// Media points

$window-xxs: 480px;
$window-xs: 640px;
$window-s: 768px;
$window-m: 1024px;
$window-l: 1280px;
$window-xl: 1440px;
$window-xxl: 1700px;

$min-window-height: 550px;

$sidebar-panel-width: 392px;