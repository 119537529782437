@use '@angular/material' as mat;

@mixin chart-description-tooltip-element($primary, $text-color) {
    background-color: mat.get-color-from-palette($primary, 100);
    color: $text-color;
    width: max-content;
    display: block;
    position: absolute;
    top: -2.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 2px;
    @include mat.elevation(4);
    left: 50%;
    transform: translate(-50%, 0);
}
