@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin multi-select-autocomplete-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);
    .multi-select-autocomplete-box-search {
        background: colors.$bg-surface-4;
        @include mat.typography-level($typography, body-1);
    }

    .multi-select-autocomplete-select-panel {
        display: flex;
        flex-direction: column;
        max-height: 22.5rem !important;
    }

    .no-checkbox-option {
        .mdc-list-item__end,
        .mat-pseudo-checkbox {
            display: none;
        }
    }

    .no-items-found {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }
}
