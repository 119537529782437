@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/solidus-black/typography';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin tippy-tooltip-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .tippy-box.trds-tooltip,
    .tippy-box.trds-tooltip[data-theme="trds-black-theme"],
    .tippy-box[data-theme="trds-black-theme"],
    trds-cell-tooltip .cell-tooltip-value,
    trds-date-cell-tooltip .cell-tooltip-value,
    trds-failure-status-tooltip .cell-tooltip-value,
    .ag-tooltip-custom .cell-tooltip-value {
        background-color: colors.$bg-surface-2;
        border-radius: $radius-sm;
        border: 1px solid colors.$tooltip-bd-color;
        @include mat.elevation(4);
        color: mat.get-color-from-palette($foreground, text);
        @include mat.typography-level($typography, body-1);
        @include trds-tooltip();
        overflow: visible;
        word-wrap: break-word;
        
        .tippy-arrow:before {
            border: none;
            width: 9px;
            height: 9px;
            background: colors.$bg-surface-2;
            transform: rotate(45deg);
        }

        &.ids-content {
            max-width: 40rem;
            max-height: max-content;
            white-space: break-spaces;
        }


        &.multiline-tooltip {
            white-space: pre-line;
            max-width: 300px;

            &.center {
                text-align: center;
            }
        }

        &.tooltip-lg {
            max-width: 38.5rem;
        }


        &.left {
            white-space: pre-line;
            text-align: left;
            overflow: auto;
            max-width: 400px;
            max-height: unset;
        }

        &.fullwidth-tooltip {
            max-width: max-content;
        }

        &.width-md {
            max-width: 290px;
        }

        &.text-capitalize {
            text-transform: capitalize;
        }

        &.file-tooltip {
            max-width: 60vw;
            word-break: break-all;
        }

        &[data-placement^=left]>.tippy-arrow:before {
            border-top: 1px solid colors.$tooltip-bd-color;
            border-right: 1px solid colors.$tooltip-bd-color;
        }

        &[data-placement^=top]>.tippy-arrow:before {
            border-right: 1px solid colors.$tooltip-bd-color;
            border-bottom: 1px solid colors.$tooltip-bd-color;
            left: 6px;
        }

        &[data-placement^=right]>.tippy-arrow:before {
            border-left: 1px solid colors.$tooltip-bd-color;
            border-bottom: 1px solid colors.$tooltip-bd-color;
            top: 7px;
        }
        
        &[data-placement^=bottom]>.tippy-arrow:before {
            border-left: 1px solid colors.$tooltip-bd-color;
            border-top: 1px solid colors.$tooltip-bd-color;
        }

    }
    .ag-tooltip-custom .cell-tooltip-value { 
        margin-top: (-$half-gap);
    }
}
