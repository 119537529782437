@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin api-key-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    trds-api-key {
        .api-key-container:not(:last-child) {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .api-key-date .label {
            font-size: mat.font-size($typography, body-2);
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
