@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin query-info-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);

    .trds-query-info {
        .info {
            z-index: 5; // over the chart
            background-color: rgba(colors.$bg-surface-1, 0.85);

            .message {
                border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

                &.success {
                    color: colors.$score-low;
                }

                &.warning {
                    color: colors.$low-priority;
                }

                &.error {
                    color: mat.get-color-from-palette($warn, default);
                }
            }

            .header {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
