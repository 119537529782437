@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin scrollbar-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    /* Works on Chrome/Edge/Safari */
    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: mat.get-color-from-palette($foreground, slider-off);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    .mdc-tooltip  ::-webkit-scrollbar-thumb {
        background-color: colors.$disabled-fill;
        border-radius: 5px;
    }
}
