@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin market-inspection-inline-selector-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);

    .selected-trigger {
        color: mat.get-color-from-palette($accent, 700) !important;
    }

    div.mat-mdc-select-panel.candle-size-panel {
        max-height: unset;
    }
}
