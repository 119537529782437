@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin market-inspection-order-book-theme($theme, $typography) {
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    .market-inspection-order-book {
        .orderbook-panel-container {
            background-color: colors.$bg-surface-1;
        }

        .delimiter-section {
            background-color: colors.$bg-surface-1;
            border-top: 1px solid colors.$bg-surface-3;
            border-bottom: 1px solid colors.$bg-surface-3;

            .bid {
                color: colors.$side-buy;
            }

            .ask {
                color: colors.$side-sell;
            }
        }
    }
}
