@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin team-details-form-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-team-details-form {
        .block-description {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .block-description-info {
            @include mat.typography-level($typography, body-2);
        }
    }
}
