@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;


@mixin mi-be-view-table-theme($theme, $typography) {
    $accent: map-get($theme, accent);
    .download-button-wrap{
        .active{
            color: mat.get-color-from-palette($accent, 700);
        }
    }
}
