@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin cases-ids-expand-panel-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-cases-ids-expand-panel {
        .prefix {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
