@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin history-event-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-history-event {
        .update-details {
            color: mat.get-color-from-palette($foreground, disabled);
        }

        .underline {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .secondary-text {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
