@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin select-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);

    .topOfList {
        border-bottom: 1px solid mat.get-color-from-palette($primary, 100);
    }
}
