@use '@angular/material' as mat;

@mixin plaid-value-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-plaid-identity-value {
        .trds-plaid-value {
            .sub-title {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }

        .value {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .name-label {
            @include mat.typography-level($typography, caption);
        }
    }
}
