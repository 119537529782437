@use '@angular/material' as mat;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-tabs-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-case-details-tabs {
        .details-section > .actions {
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .tab-comment {
            margin-bottom: $gap;
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
