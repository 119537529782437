@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin risk-model-form-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-risk-model-form, .trds-country-risk-model-form, .trds-module-risk-model-form {
        .high-score-color {
            color: colors.$score-high;
        }
        .medium-score-color {
            color: colors.$score-medium;
        }
        .low-score-color {
            color: colors.$score-low;
        }

        .title-row {
            .section-title {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }

            .legend {
                .legend-item {
                    @include mat.typography-level($typography, body-2);

                    .box {
                        &.invalid {
                            background-color: rgba(colors.$invalid-param, 0.3);
                        }

                        &.changed {
                            background-color: rgba(colors.$modified-param, 0.3);
                        }
                    }
                }
            }
        }

        .actions {
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        }
    }
}
