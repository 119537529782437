@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-ms-chart-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);

    .trds-alert-ms-chart {
        .chart-actions .lock.locked {
            color: mat.get-color-from-palette($accent);

            [mat-icon-button]:hover,
            &:hover {
                color: mat.get-color-from-palette($accent, 100);
            }
        }
    }
}
