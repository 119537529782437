@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin risk-config-audit-dialog-theme($theme) {
    $foreground: map-get($theme, foreground);
    .trds-risk-config-audit-dialog {
        .risk-audit-header {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        h4 {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
