@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin assign-user-component-theme($theme, $typography) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // this theme is used in two components: AssignUserComponent and ReassignEscalationComponent
    .trds-assign-user,
    .trds-reassign-escalation {
        .label {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
