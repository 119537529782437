@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin card-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    // Define any styles affected by the theme.
    mat-card.mat-mdc-card {
        // detailed header used to override material built-in styles
        padding: $three-half-gaps;
        background: colors.$bg-surface-2;

        &.hovered-card:not(.disabled):hover {
            background: colors.$bg-surface-3;
            @include mat.elevation(4);
        }

        &.disabled {
            color: colors.$disabled-color;

            .info-icon {
                display: none;
            }
        }

        mat-card-content,
        mat-card-content:last-child {
            padding: 0;
        }
    }

    .card-title {
        @include mat.typography-level($typography, headline-6);
    }
}
