@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin price-chart-config-dialog-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);

    .trds-price-chart-config-dialog {
        .line-weigh-controller {
            .up {
                color: mat.get-color-from-palette($primary, 500);
            }
    
            .down {
                color: mat.get-color-from-palette($primary, 500);
            }
        }

        trds-mi-overlay button[mat-button] {
            border-radius: $radius-sm;
        }
    }


    .chart-config-wrapper {
        max-width: none;
        background-color: colors.$bg-surface-3;
        border-radius: $radius-sm;
    }
}
