@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin two-thumb-slider-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    trds-two-thumb-slider {
		.track {
			background: mat.get-color-from-palette($foreground, disabled-button);	
		}

		.range-left {
			background: mat.get-color-from-palette($accent, 500);
			
			&:after,
			.thumb-tooltip {
				background: mat.get-color-from-palette($accent, 500);
				color: colors.$light-highlight-text;
			}
		}

		.type-range {
			.track {
				background-color: colors.$score-high;
			}
			
			.range{
				background-color: colors.$score-medium;
				
				&:after,
				.thumb-tooltip{
					background-color: colors.$score-medium;
				}
			}
		
			.range-left{
				background-color: colors.$score-low;
				
				&:after,
				.thumb-tooltip{
					background-color: colors.$score-low;
					color: mat.get-color-from-palette($foreground, text);
				}
			}

		}
		
		
		.thumb-tooltip{
			@include trds-h6();
			line-height: 1.43rem;
		}
		
		.values {
            @include mat.typography-level($typography, body-2);
		}


		.disabled {
			.range-left,
			.range-left::after,
			.range,
			.range::after,
			.thumb-tooltip,
			.track{
				background: colors.$disabled-fill;
			}
		}
		
    }
}



