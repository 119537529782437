@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin chips-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    $accent: map-get($theme, accent);

    mat-chip-option.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled),
    mat-chip-row.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled),
    mat-chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        // needs save depth level of 2 because of requirements of product
        background-color: colors.$bg-surface-5;
        @include mat.typography-level($typography, body-2);

        &.mat-accent {
            background-color: mat.get-color-from-palette($accent, 500);
            color: colors.$app-background;
        }
    }

    .mat-mdc-chip-graphic {
        max-width: 0;
        visibility: hidden;
    }

    mat-chip-listbox.no-focus:focus {
        outline: none;
    }
}
