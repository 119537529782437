@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin object-dictionary-item-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    .trds-object-dictionary-item {
        .item {
            background-color: colors.$bg-surface-3;
        }

        .changed {
            background-color: rgba(colors.$modified-param, 0.3);
        }

        .invalid {
            background-color: rgba(colors.$invalid-param, 0.3);
        }

        .disabled {
            opacity: 0.7;
        }

        .second-row.highligh,
        .second-row:focus-within {
            background-color: rgba(mat.get-color-from-palette($accent, 500), 0.1);
            .mat-icon {
                color: mat.get-color-from-palette($accent, 500);
            }
        }

        .original {
            @include mat.typography-level($typography, caption);
        }

        .second-row .wide-field {
            width: 7rem;
        }
    }
}
