@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/mixins/mixins';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-alerts-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-case-details-alerts {
        .container {
            background-color: colors.$bg-surface-1;
        }
    }
}
