@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-attachment-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    trds-case-details-attachments {
        .file-uploadAt {
            color: mat.get-color-from-palette($foreground, secondary-text);
            margin-left: $half-gap;
        }
    }
}
