@use '@angular/material' as mat;
@use '../solidus-black/colors.scss' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin table-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    // Define any styles affected by the theme.
    mat-table.mat-mdc-table,
    [mat-table].mat-mdc-table {
        background: colors.$app-background;

        &.table-darker {
            background: colors.$bg-surface-1;
        }

        &.no-background {
            background: none;

            mat-header-cell,
            th[mat-header-cell] {
                color: mat.get-color-from-palette($primary, 500);
            }

            mat-row,
            [mat-row] {
                background: none;

                &:not(:last-child) {
                    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
                }
            }
        }

        mat-row,
        [mat-row] {
            @include row-score-color($theme, $typography);
            min-height: 52px;

            &:hover mat-cell,
            &:hover td[mat-cell] {
                background: colors.$row-hover;
            }

            &:last-child {
                border: none;
            }

            &.active {
                background: colors.$bg-surface-3;

                &:hover {
                    background: colors.$row-hover;
                }
            }

            &.disabled {
                pointer-events: none;
                touch-action: none;

                mat-cell,
                [mat-cell] {
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        mat-cell,
        [mat-cell] {
            background: colors.$bg-surface-2;
            border-bottom: 2px solid colors.$bg-surface-1;
        }

        mat-cell:not(:first-child),
        [mat-cell]:not(:first-child),
        mat-header-cell:not(:first-child),
        [mat-header-cell]:not(:first-child)  {
            padding-left: $cell-gap;
        }

        mat-cell,
        [mat-cell],
        mat-header-cell,
        [mat-header-cell] {
            font-size: inherit; // Needed for resetting material font size
            @include mat.typography-level($typography, body-1); // Needed for resetting material font family

            &:not(:last-of-type) {
                padding-right: 0;
            }
        }


        mat-header-cell,
        [mat-header-cell] {
            color: mat.get-color-from-palette($foreground, secondary-text);
            border-bottom: 1px solid  mat.get-color-from-palette($foreground, divider);
        }
    }


    mat-header-cell.align-right,
    [mat-header-cell].align-right {
        .mat-sort-header-container {
            justify-content: flex-end;
        }
    }

    mat-header-cell button {
        background: none;
        border: none;
        color: mat.get-color-from-palette($foreground, secondary-text);
    }


    .no-data {
        background: colors.$bg-surface-2;
        color: colors.$low-priority;
        text-align: center;
        padding: $gap;
        margin-top: $gap;
        box-sizing: border-box;
        @include flex-layout(center, center);
    }

    // Expanded/group table row
    .trds-group-row {
        background: colors.$bg-surface-1;
        margin: $half-gap 0;

        &:first-of-type {
            margin-top: 0;
        }

        &.expanded {
            margin-bottom: 0;
        }

        &:hover {
            background: mat.get-color-from-palette($background, hover);
        }
    }

    mat-cell.side,
    [mat-cell].side  {
        font-weight: 600;

        &.SELL {
            color: colors.$side-sell;
        }

        &.BUY {
            color: colors.$side-buy;
        }
    }


    mat-footer-row,
    [mat-footer-row] {
        background: colors.$bg-surface-1;
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .mat-sort-header-arrow {
        color: mat.get-color-from-palette($accent, 500);
    }

    .error-message {
        &.no-results {
            color: colors.$low-priority;
            text-align: center;
            padding: $gap;
        }

        &.error-loading {
            @include flex-layout(center, center);
            color: mat.get-color-from-palette($warn, A200);
            padding: $gap 0;

            .mat-icon {
                margin-right: $half-gap;
            }
        }
    }

    .nav-arrow {
        transform: rotate(-45deg);
        &:hover {
            color: mat.get-color-from-palette($foreground, icon);
        }
    }

    @media screen and (max-width: 1500px) {
        mat-cell .priority-text,
        [mat-cell] .priority-text {
            display: none;
        }
    }
}
