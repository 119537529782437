@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin cases-bulk-export-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-cases-bulk-export {
        .info-panel {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0 0.5rem 0 1.71rem;
            height: 3.4rem;

            h3 {
                color: rgba(255, 255, 255, 0.7);
                margin: 0;
            }
        }
    }
}
