@use 'sass:math';
@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@use 'src/theme/solidus-black/typography' as typography;
@import 'src/theme/variables.scss';

$label-icon-size: 1.14rem;
$chip-icon-size: 1.3rem;

$label-border-width: 2px;

$vertical-outline-gap: 4px;
$vertical-flat-gap: $vertical-outline-gap + $label-border-width;

$horizontal-outline-gap: 8px;
$horizontal-flat-gap: $horizontal-outline-gap + $label-border-width;

$large-flat-gap: 10px;

@mixin label-icon($icon-size) {
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;
    line-height: 1;
}

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin label-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-label {
        // Structure
        min-width: $btn-min-size;
        border-radius: $font-size;
        line-height: $label-icon-size;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        box-sizing: border-box;
        white-space: nowrap;
        user-select: none;
        outline: none;
        padding: $vertical-outline-gap $horizontal-outline-gap;
        border: 2px solid colors.$bg-surface-5;
        max-width: 100%;
        text-transform: capitalize;

        &.uppercase {
            text-transform: uppercase;
        }

        &.completed {
            border-color: colors.$score-low;
        }

        &.failed {
            border-color: colors.$score-high;
        }

        &.pending,
        &.new {
            border-color: colors.$low-priority;
        }

        &.running,
        &.in_progress {
            border-color: colors.$chart-blue;
        }

        &.partial_success,
        &.partially_completed {
            border-color: colors.$side-buy;
        }

        &.review-status {
            white-space: normal;
            padding: $two-third-gap $three-quarter-gaps;
        }

        // keep labels if repeated
        &:not(:last-of-type) {
            margin-right: 4px;
        }

        .trds-label-wrapper {
            @include flex-layout(center, center);

            trds-mat-icon .mat-icon {
                display: block;
            }
        }

        &.trds-flat {
            background-color: colors.$bg-surface-5;
            border: none;
            padding: $vertical-flat-gap $horizontal-flat-gap;
        }

        &.trds-no-border {
            border: none;
        }

        &.trds-icon {
            trds-mat-icon,
            .mat-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
                display: inline-block;
                @include label-icon($label-icon-size);
            }

            .label {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 0.4rem;
            }
        }

        &.trds-large-height {
            padding: $large-flat-gap;
        }

        &.trds-capitalize {
            text-transform: capitalize;
        }

        &.trds-chip {
            font-size: mat.font-size($typography, body-2);
            padding: $half-gap 0;
            margin-right: $half-gap;
            line-height: $chip-icon-size;
            cursor: pointer;
            border: none;
            text-transform: capitalize;

            .mat-icon {
                @include label-icon($chip-icon-size);
                opacity: 0.4;
                margin-right: $half-gap;
            }

            &:hover .mat-icon {
                opacity: 0.6;
            }
        }

        &.trds-badge {
            border-radius: $radius-sm;
            padding: 0 $one-third-gap;
            background-color: mat.get-color-from-palette($primary, 500);
            color: colors.$light-highlight-text;

            &.dark-badge {
                border-radius: 1rem;
                border: none;
                padding: 0.25rem calc(1rem + 0.25rem);
                background-color: colors.$bg-surface-3;
                color: mat.get-color-from-palette(colors.$solidus-primary, 100);
            }
        }

        & ~ .trds-badge {
            margin-left: $quarter-gap;
        }

        &.trds-flat.trds-hover:hover,
        &.trds-badge.trds-hover:hover {
            background-color: mat.get-color-from-palette($accent, 100);
            color: colors.$light-highlight-text;
        }

        mat-cell &,
        [mat-cell] &,
        .trds-grid .value &{
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }

    @media screen and (max-width: 1500px) {
        mat-cell .trds-label.trds-icon .label,
        [mat-cell] .trds-label.trds-icon .label {
            display: none;
        }
    }
}
