@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin status-button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $warn: map-get($theme, warn);

    // Define any styles affected by the theme.
    .trds-status-button {
        .success {
            color: colors.$score-low;

            &.active,
            &:hover {
                color: rgba(colors.$score-low, 0.85);
            }
        }

        .warning {
            color: colors.$low-priority;

            &.active,
            &:hover {
                color: rgba(colors.$low-priority, 0.85);
            }
        }

        .error {
            color: mat.get-color-from-palette($warn, default);

            &.active,
            &:hover {
                color: mat.get-color-from-palette($warn, default);
                color: rgba(mat.get-color-from-palette($warn, default), 0.85);
            }
        }
    }
}
