@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin login-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    .trds-login {
        .secondary-title {
            @include mat.typography-level($typography, headline-5);
            color: mat.get-color-from-palette($foreground, text);
        }

        .hoverable-hint-buttons {
            @include mat.typography-level($typography, body-2);
        }

        .link {
            color: mat.get-color-from-palette($foreground, text);
            &:hover {
                color: mat.get-color-from-palette($accent, 100);
            }
        }
    }
}
