@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/components/scrollbar.theme';
@import 'src/theme/variables.scss';
@import 'src/theme/mixins/mixins';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin comments-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-comments {
        display: flex;

        .comments,
        .new {
            background-color: colors.$bg-surface-2;
        }

        .comments-list {
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        }

        .new mat-form-field {
            margin: $gap 0;
        }

        .comment {
            &:not(:last-child) {
                border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
            }

            .user {
                @include mat.typography-level($typography, subtitle-2);
            }

            .time {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
