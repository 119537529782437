@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ms-inner-table-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);

    // Define any styles affected by the theme.
    .trds-ms-inner-table [mat-table]{
        mat-cell,
        [mat-cell] {
            font-size: mat.font-size($typography, body-2);
        }
    }
}
