@use '@angular/material' as mat;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin pwd-min-requirements-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-pwd-min-requirements {
        ul li {
            @include mat.typography-level($typography, body-2);
            color: mat.get-color-from-palette($foreground, secondary-text);

            &.checked {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
    }
}
