@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-summary-theme($theme) {
    $foreground: map-get($theme, foreground);

    .summary textarea {
        background: none;
        border: none;
        font: inherit;
        outline: none;
        color: mat.get-color-from-palette($foreground, text);
    }
}
