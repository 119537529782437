@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin list-theme($theme, $typography) {
    // Define any styles affected by the theme.
    mat-list {
        mat-list-item,
        mat-list-option {
            font-size: mat.font-size($typography, body-1);
        }

        &.selection-list mat-list-option {
            min-height: 3.4rem;
            display: flex;
            height: unset;
            padding: $quarter-gap 0;
            word-break: break-word;
            
            & > * {
                width: 100%;
            }
        }
    }
}
