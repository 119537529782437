@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin overview-page-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-overview {
        .section-container {
            border-top: 1px solid mat.get-color-from-palette($foreground, divider);
            border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

            .section-title,
            .section-title-icon {
                &.disabled {
                    color: colors.$disabled-color;
                }
            }
        }
    }
}
