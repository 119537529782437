@mixin small-btn-styles() {
    height: $small-icon-size;
    width: $small-icon-size;
    line-height: $small-icon-font;
    padding: 0;

    mat-icon {
        @include small-icon-styles();
    }
}

@mixin small-icon-styles() {
    box-sizing: border-box;
    height: $small-icon-size;
    width: $small-icon-size;
    min-width: $small-icon-font;
    font-size: $small-icon-font;
    line-height: $small-icon-font;
    padding: 1px;
}

@mixin xs-icon-styles() {
    height: $font-size;
    width: $font-size;
    font-size: $xs-icon-size;
}

@mixin info-icon-styles() {
    .mat-icon {
        height: $font-size;
        width: $font-size;
        font-size: $font-size;
        margin-left: 4px;
        vertical-align: middle;
        line-height: 1;

        &:hover {
            cursor: default;
        }
    }
}

@mixin risk-menu-button() {
    padding: 0 $half-gap;
    .mat-icon {
        @include small-icon-styles();
    }
    .risk-select {
        @include flex-layout(center, space-between);
        min-width: 90px;
        line-height: 1;
    }
}

@mixin icon-size($icon-size) {
    font-size: $icon-size;
    width: $icon-size;
    height: $icon-size;
    line-height: 1;

    svg {
        width: $icon-size;
        height: $icon-size;
    }
}

@mixin tool-icon-button($button-size: 2.5rem) {
    @include flex-layout(center, center);
    width: $button-size;
    height: $button-size;
    min-width: $button-size;
}
