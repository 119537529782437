@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin toggle-button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);

    mat-button-toggle {
        &.mat-button-toggle-appearance-standard {
            color: mat.get-color-from-palette($primary, 500);
            background: transparent;

            &.mat-button-toggle-checked {
                background-color: mat.get-color-from-palette($accent, 500);
                color: colors.$app-background;
            }

            &.mat-button-toggle-disabled {
                background: none;
                color: mat.get-color-from-palette($foreground, disabled);

                &.mat-button-toggle-checked{
                    background-color: mat.get-color-from-palette($foreground, disabled-button);
                }
            }
        }

        &.mini-toggle-btn {
            background-color: colors.$bg-surface-2;

            &.mat-button-toggle-disabled {
                background-color: colors.$bg-surface-2;
            }

            &.mat-button-toggle-checked {
                background-color: mat.get-color-from-palette($accent, 500);
                color: colors.$bg-surface-2;
            }

            .mat-button-toggle-label-content {
                padding: 0px $quarter-gap;
                line-height: $small-icon-size;
            }

            mat-icon {
                display: block;
            }
        }

        .mat-pseudo-checkbox {
            display: none;
        }
    }
}
