@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin link-dialog-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Define any styles affected by the theme.

    .trds-link-dialog {
        .main {
            @include mat.typography-level($typography, headline-6);
        }

        .input .explain {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .server-error {
            color: mat.get-color-from-palette($warn);
        }
    }
}
