@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin status-card-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-status-card {
        // Define any styles affected by the theme.
        .status-card {
            .card-title {
                text-transform: uppercase;
                margin-bottom: 0;

                h3 {
                    @include mat.typography-level($typography, body-1);
                    font-size: 1rem;
                }
            }

            .count {
                @include mat.typography-level($typography, headline-5);
                font-size: 2.28rem;
                line-height: 2.86rem;
            }

            .score {
                @include mat.typography-level($typography, body-2);
            }

            .info-icon {
                font-size: 1.2rem;
                height: initial;
            }

            @media screen and (max-width: 910px) {
                .count {
                    font-size: 2rem;
                }

                .score {
                    font-size: 0.75rem;
                }

                .info-icon {
                    font-size: 1rem;
                }
            }
        }
    }
}
