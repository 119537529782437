@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin cell-boost-theme($theme) {
    $accent: map-get($theme, accent);

    .trds-cell-boost.link,
    .trds-cell-boost[copy],
    .trds-cell-boost[ng-reflect-copy="true"] {
        .content:not(:disabled):hover,
        .mat-icon:hover {
            color: mat.get-color-from-palette($accent, 100);
        }
    }

    .trds-cell-boost.link .content:not(:disabled):hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .search-icon {
        color: mat.get-color-from-palette($accent, 100);
    }
}
