@use '@angular/material' as mat;

@mixin notify-dialog-theme($theme, $typography) {
    $warn: map-get($theme, warn);

    .cases-notify-dialog {
        .warning {
            color: map-get($warn, default);
        }

        .caption {
            @include mat.typography-level($typography, body-2);
        }

        .trds-spinner-button {
            margin-left: $gap;
        }

        mat-hint {
            color: map-get($warn, default);
        }
    }
}
