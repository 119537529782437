@use 'sass:math';
@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin input-theme($theme) {
    $foreground: map-get($theme, foreground);
    ////////////////////
    // Number input: hide arrows
    ////////////////////

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input {
        color: mat.get-color-from-palette($foreground, text);
    }

    input::placeholder {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    input[type='time']::after {
        display: block;
        height: 0;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        display: none;
    }

    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: inset 0 0 0 30px colors.$bg-surface-2!important;
        -webkit-text-fill-color: mat.get-color-from-palette($foreground, base);
        transition: background-color 5000s ease-in-out 0s !important;
    }
}
