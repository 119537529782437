@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin register-2fa-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);

    .trds-register-2fa {
        color: mat.get-color-from-palette($foreground, secondary-text);

        .register-container {

            .step-details {
                h2 {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }

            .instruction-step {
                .step-number {
                    background-color: mat.get-color-from-palette($foreground, base);
                    color: colors.$app-background;
                }

                .link {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }

            .secret-key {
                @include mat.typography-level($typography, subtitle-2);
            }

            .warning-message-container {
                h2,
                .warning-notice {
                    color: mat.get-color-from-palette($warn, default);
                }
            }
        }
    }
}
