@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/mixins/mixins';

@mixin news-theme($theme, $typography) {
    $accent: map-get($theme, accent);

    .header{
        @include flex-layout($valign: center, $halign: space-between);
    }

    .news-card{
        border-bottom: solid 3px colors.$bg-surface-3;
    }

    .link{
        &:hover{
            color: mat.get-color-from-palette($accent, 100);
        }
    }

    .no-news{
        height: 100%;
        width: 100%;
        @include flex-layout($valign: center, $halign: center);
        color: colors.$low-priority;
    }
}
