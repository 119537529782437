@use '@angular/material' as mat;

@mixin match-result-theme($theme, $typography) {
    $background: map-get($theme, background);

    .trds-match-result {
        .match-result-title {
            @include mat.typography-level($typography, subtitle-1);
        }
    }
}
