@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin market-inspection-chart-description-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);

    $disabled-border-color: mat.get-color-from-palette($primary, 700);
    $selected-border-color: mat.get-color-from-palette($accent, 500);

    .market-inspection-chart-description {
        .side-circle {
            &.buy {
                background-color: colors.$side-buy;
            }

            &.sell {
                background-color: colors.$side-sell;
            }

            &.alert {
                background-color: mat.get-color-from-palette($accent, 100) !important;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .alert-side-circle {
            &.alert {
                background-color: mat.get-color-from-palette($accent, 100) !important;
            }
        }

        .side-circle-btn {
            border: 2px solid transparent;
            &:disabled {
                border: 2px solid $disabled-border-color;
            }

            &.selected {
                border: 2px solid $selected-border-color;

                &:disabled {
                    border: 2px solid $disabled-border-color;
                }
            }
        }

        .event-type-icon {
            .mat-icon:disabled {
                color: $selected-border-color;
            }
        }

        .disabled {
            opacity: 0.7;
        }
    }

    .horizontal-center {
        .alert-button {
            border-color: mat.get-color-from-palette(colors.$solidus-accent, 500) !important;
        }
        .active-btn {
            background-color: rgba(255, 205, 38, 0.15);
        }

       
    }

    .selected-text{
        color: mat.get-color-from-palette(colors.$solidus-accent, 100);
        
    }
    .hover{
        &:hover{
            cursor: pointer;
            color: mat.get-color-from-palette(colors.$solidus-accent, 100);
        }
    }

}
