@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin history-item-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-history-item {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

        .history-item-footer {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .from-to-container {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
